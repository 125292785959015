import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class PrivacyPoliciesProvider extends HttpRequest {
  getLastItem () {
    this.setHeader(getAuthToken())
    return this.get('/cms/privacy-policies')
  }

  updateItem (payload) {
    this.setHeader(getAuthToken())
    return this.post('/cms/privacy-policies', payload)
  }
}

export default PrivacyPoliciesProvider
